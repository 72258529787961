import React from 'react'
import Layout from '../../../../components/App/Layout'
import Navbar from '../../../../components/App/NavbarRV'
import Footer from '../../../../components/App/FooterRV'
import KDPImagePage from '../../../../assets/images/pages/2-4-1-0.jpg'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <div>
                <div className='container'>
                    <div className='page-title-content'>
                        <ul>
                            <li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/risques/'>Catastrophe Naturelle</a></li><li>Après sécheresse</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="services-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="services-details-desc">

                                <h1>Qu’est ce que l’expertise sécheresse ?</h1>
                                <p>Vous avez constaté l’apparition de fissures sur votre ouvrage suite à un épisode de fortes chaleurs. Suite à l’approbation d’un Arrêté CatNat, vous avez déclaré votre sinistre auprès de votre assurance. Vous rentrez normalement dans les garanties comprises dans votre contrat d’assurance (MRH) mais les conclusions de l’Expert mandaté par votre assurance excluent la sécheresse comme facteur déterminant de vos désordres.</p>
                                <div className='services-details-image'>
                                    <img src={KDPImagePage} alt="Après inondation" />
                                </div>

                                <p>En cas de refus de garantie par votre assureur au titre de la garantie catastrophe naturelle, vous avez la possibilité d’engager votre propre expert. Celui-ci interviendra pour défendre vos intérêts et apporter un contre-avis, indépendant, neutre et impartial.</p>

                                <h2>Le sinistre sécheresse, un sinistre souvent mal garanti</h2>

                                <p>En 2018, le coût des sinistrés liés à la sécheresse est estimé entre 1,1 et 1,3 milliards d’euros et ont concerné plus 4000 communes selon la Caisse Centrale des réassurances.</p>


                                <h2>Le sinistre s&eacute;cheresse, un sinistre souvent mal garanti</h2>
                                <p>En 2018, le co&ucirc;t des sinistr&eacute;s li&eacute;s &agrave; la s&eacute;cheresse est estim&eacute; entre 1,1 et 1,3 milliards d&rsquo;euros et ont concern&eacute; plus 4000 communes selon la Caisse Centrale des r&eacute;assurances.</p>
                                <p>De ce fait, le nombre de d&eacute;clarations de sinistre explose mais ne sont pas toujours garantis !</p>
                                <p><span>Pour une partie d&rsquo;entre eux, l&rsquo;assurance prend en charge les dommages et les r&eacute;parations mais bien souvent, les dommages ne sont pas pris en charge dans des situations o&ugrave; les co&ucirc;ts de r&eacute;paration peuvent parfois s&rsquo;&eacute;lever &agrave; plus de 100 000 euros !</span></p>
                                <p><span>Lorsque vous vous estimez l&eacute;s&eacute;s par votre assurance, faire appel &agrave; un Expert sp&eacute;cialiste des sinistres s&eacute;cheresse, peut faire basculer la d&eacute;cision de l&rsquo;assureur et vous garantir vos dommages. </span></p>
                                <h2><span>Pourquoi faire appel &agrave; un expert s&eacute;cheresse ?</span></h2>
                                <p><span>L&rsquo;expert d&rsquo;assur&eacute; sp&eacute;cialis&eacute; en Catastrophe naturelle s&eacute;cheresse est un professionnel du b&acirc;timent ind&eacute;pendant, mandat&eacute; par l&rsquo;assur&eacute;.</span></p>
                                <p><span>Sa principale mission est de vous accompagner dans vos d&eacute;marches jusqu&rsquo;&agrave; la juste indemnisation de votre sinistre.</span></p>
                                <p><span>Il peut intervenir avant ou apr&egrave;s le passage de l&rsquo;expert d&rsquo;assurance.</span></p><p><span></span></p>
                                <p><span>La mission de l&rsquo;expert d&rsquo;assur&eacute; s&eacute;cheresse est de mener d&rsquo;inspection technique minutieuse des d&eacute;sordres sur votre ouvrage afin de r&eacute;pondre aux questions suivantes : &nbsp;</span></p>
                                <ul><li><span>Les d&eacute;sordres sont-ils d&rsquo;ordre structurel ? </span></li>
                                <li><span>Sont-ils la r&eacute;sultante de ph&eacute;nom&egrave;nes de tassement diff&eacute;rentiel ? </span></li>
                                <li><span>La s&eacute;cheresse est-elle le facteur d&eacute;terminant d&rsquo;apparitions des fissures ? </span></li>
                                </ul><p><span></span></p><p><span>De par son exp&eacute;rience et ses comp&eacute;tences, l&rsquo;Expert s&eacute;cheresse </span></p>
                                <ul><li><span>est capable d&rsquo;analyser les fissurations sur votre b&acirc;ti pour en d&eacute;terminer les causes et origines,</span></li>
                                <li><span>d&rsquo; interpr&eacute;ter l&rsquo;analyse de Sol (g&eacute;n&eacute;ralement G5) et d&eacute;finir les indicateurs pr&eacute;pond&eacute;rants,</span></li>
                                <li><span>d&eacute;terminer si la s&eacute;cheresse est le facteur d&eacute;terminant d&rsquo;apparition des fissures.</span></li>
                                </ul><p><span></span></p><p><span>Cette derni&egrave;re condition, si elle est reconnue, ouvre vos droits &agrave; la garantie de votre sinistre en catastrophe naturelle s&eacute;cheresse. </span></p>
                                <h2><span>D&eacute;roulement de la mission d&rsquo;expertise s&eacute;cheresse</span></h2><p><span></span></p>
                                <p><span>La mission de l&rsquo;Expert s&eacute;cheresse se d&eacute;roule en 3 phases.</span></p><p><span></span></p>
                                <ul><li><span>Phase 1 : L&rsquo;Expert traite votre demande et r&eacute;cup&egrave;re vos informations (photos, plan de masse, plan de surface) pour une premi&egrave;re analyse pr&eacute;alable afin de pr&eacute;parer au mieux sa visite technique</span></li>
                                <li><span>Phase 2 - visite technique. L&rsquo;Expert se rend sur votre site pour proc&eacute;der &agrave; une inspection d&eacute;taill&eacute;e de votre ouvrage, relever les d&eacute;sordres de type fissures afin d&rsquo;avoir un maximum d&rsquo;&eacute;l&eacute;ments pour mener &agrave; bien son expertise. Si une &eacute;tude de sol a &eacute;t&eacute; r&eacute;alis&eacute;e, celle-ci sera minutieusement &eacute;tudi&eacute;e. A contrario, l&rsquo;&eacute;tude de sol sera demand&eacute;e &agrave; l&rsquo;assurance qui finance cette &eacute;tude.</span></li>
                                <li><span>Phase 3 : remise du rapport. En fonction des conclusions de votre expert, vous avez alors la possibilit&eacute; d&rsquo;une demande de contre-expertise lors de laquelle votre expert d&rsquo;assur&eacute; s&eacute;cheresse et l&rsquo;expert d&rsquo;assurance vont exposer leur argumentaire. En cas de d&eacute;saccord, </span><span class="c4">il est g&eacute;n&eacute;ralement demand&eacute; la r&eacute;alisation d&rsquo;une &eacute;tude de sol de type G5 </span><span>(apr&egrave;s sinistre). Cette &eacute;tude est fondamentale pour la prise de position de l&rsquo;Expert car elle fournit des indicateurs permettant de savoir si le sol situ&eacute; sous vos fondations ont une propension au retrait et au gonflement</span></li></ul>
                                <p><span></span></p>
                                <p><span>Sur le plan technique, il m&egrave;ne en premier lieu une mission d&rsquo;inspection technique sur site et va r&eacute;pondre aux questions suivantes : &nbsp;</span></p>
                                <ul><li><span>Les d&eacute;sordres sont-ils d&rsquo;ordre structurel ? </span></li>
                                <li><span>Sont-ils la r&eacute;sultante de ph&eacute;nom&egrave;nes de tassement diff&eacute;rentiel ? </span></li>
                                <li><span>La s&eacute;cheresse est-elle le facteur d&eacute;terminant d&rsquo;apparitions des fissures ? </span></li></ul>
                                <p><span></span></p><p><span>De par son exp&eacute;rience et ses comp&eacute;tences, l&rsquo;Expert s&eacute;cheresse </span></p>
                                <ul><li><span>est capable d&rsquo;analyser les fissurations sur votre b&acirc;ti pour en d&eacute;terminer les causes et origines,</span></li>
                                <li><span>d&rsquo; interpr&eacute;ter l&rsquo;analyse de Sol (g&eacute;n&eacute;ralement G5) et d&eacute;finir les indicateurs pr&eacute;pond&eacute;rants,</span></li>
                                <li><span>d&eacute;terminer si la s&eacute;cheresse est le facteur d&eacute;terminant d&rsquo;apparition des fissures.</span></li></ul>
                                <p><span>Cette derni&egrave;re condition, si elle est reconnue, ouvre vos droits &agrave; la garantie de votre sinistre en catastrophe naturelle s&eacute;cheresse. </span></p>
                                <p><span></span></p><h2><span>Le cabinet RV EXPERTISES, sp&eacute;cialiste des sinistres &ldquo;s&eacute;cheresse&rdquo; et de d&eacute;fense de l&rsquo;Assur&eacute;</span></h2>
                                <p><span></span></p><p><span>Les dossiers d&rsquo;assistance dans le cadre de litige catastrophe naturelle constituent 70 % des dossiers trait&eacute;s par le cabinet RV EXPERTISES. </span></p>
                                <p><span>Notre exp&eacute;rience coupl&eacute;e &agrave; nos comp&eacute;tences s&rsquo;appuient sur des analyses d&rsquo;ing&eacute;nierie structure permettant ainsi de distinguer les fissures dites structurelles de celles r&eacute;sultant de ph&eacute;nom&egrave;nes de tassement de sol d&rsquo;assise des fondations.</span></p>
                                <p><span></span></p><p><span>&Ecirc;tre assist&eacute; d&rsquo;un Expert sp&eacute;cialis&eacute; dans les sinistres s&eacute;cheresse peut faire basculer les conclusions d&rsquo;une Expertise</span></p><p><span></span></p>
                                <p><span>Dans ces situations, le choix d&rsquo;un bon Expert est essentiel. Ses comp&eacute;tences et son exp&eacute;rience peuvent faire basculer la tenue d&rsquo;un dossier s&eacute;cheresse et faire accepter, par des arguments techniques, la s&eacute;cheresse comme facteur d&eacute;terminant.</span></p>
                                <p><span></span></p><p><span>Faites appel &agrave; un Expert RV EXPERTISES sp&eacute;cialis&eacute; depuis plus de 30 ans dans la th&eacute;matique de la pathologie de la construction, les risques naturels et les analyses g&eacute;otechniques. </span></p>
                                <p><span>Contacter un expert s&eacute;cheresse.</span></p>



                                <div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>





                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="services-details-info">
                                <ul className="services-list">

                                    <li><a href='/expertise-assure/risques/inondation/' className='active'>Après sécheresse</a></li>

                                </ul>
                                <a href='javascript:history.go(-1)'>Retour</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    );
}

export default Details